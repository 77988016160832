<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center" v-if="checkPermission('AddProduit')">
        <router-link 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-produit">
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un produit
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button" >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
       <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un produit" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Image
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Nom
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Catégorie (s)
              </th> -->
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Collection (s)
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Quantité
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Prix
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Prix vente
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(produit, index) in produits" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <img :src="getUrlApiForProductFiles(produit.urlImage)" alt="Image" width="50" height="50">
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ produit.title.slice(0, 10) }}
                <!-- {{ produit.is_hot }}
                {{ produit.is_featured }} -->
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div class="col-12">
                    <span v-for="categorie in produit.categories" :key="categorie.id" class="badge-container">
                      <div class="col-6">
                      <span class="badge badge-outline-danger text-outline-primary">
                        {{ categorie.name }}
                        <a href="javascript:void(0);"
                          class="tag-delete-link text-outline-danger"
                          @click="suppCat(categorie.id,produit.id)">
                          <i class="flaticon-delete icon-position"></i>
                        </a>
                      </span>
                    </div>
                    </span>
                  </div>
              </td> -->
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  <div class="col-12">
                      <span v-for="collection in produit?.collections" :key="collection.id" class="badge-container">
                        <div class="col-6">
                        <span class="badge badge-outline-danger text-outline-primary">
                          {{ collection.name }}
                          <a href="javascript:void(0);"
                            class="tag-delete-link text-outline-danger"
                            @click="suppression(collection.id, produit?.collections, 'produitcategorie', `la catégorie ${categorie.name}`)">
                            <i class="flaticon-delete icon-position"></i>
                          </a>
                        </span>
                      </div>
                      </span>
                    </div>
                </td> -->
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ produit?.depot }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ produit.price }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span class="badge badge-outline-danger text-outline-primary">
                  {{ produit.statut ? "Actif" : "Non Actif" }}  </span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('AddCategorie')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewCategorieModal"
                        @click="openAddNewCategorieModal(produit)"
                      >
                      <i class="flaticon-plus lh-1 me-8 position-relative top-1"></i>
                        Ajouter de (s) catégorie (s)
                      </a>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddCollectionModal"
                        @click="openAddCollectionModal(produit)"
                      >
                      <i class="flaticon-price-tag-1  lh-1 me-8 position-relative top-1"></i>
                        Ajouter dans une collection
                      </a>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        @click="switchProduit(produit.id)"
                      >
                      <i class="flaticon-flash  lh-1 me-8 position-relative top-1"></i>
                          <span v-if="!produit.statut"> Activer le produit</span>
                          <span v-if="produit.statut"> Désactiver le produit</span>
                      </a>
                    </li>
                    <li v-if="!produit.is_sale">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddPromotionModal"
                        @click="openAddPromotionModal(produit, produit.sale_price)"
                      >
                      <i class="flaticon-giftbox lh-1 me-8 position-relative top-1"></i>
                        Mettre en promotion
                      </a>
                    </li>
                    <li v-if="produit.is_sale">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        @click="confirmEndPromo(produit.id)"
                      >
                      <i class="flaticon-close-1  lh-1 me-8 position-relative top-1"></i>
                        Retirer de promotion
                      </a>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        @click="switchFeature(produit.id)"
                      >
                      <i class="flaticon-flash  lh-1 me-8 position-relative top-1"></i>
                          <span v-if="!produit.is_featured"> Activer feature</span>
                          <span v-if="produit.is_featured"> Désactiver feature</span>
                      </a>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        @click="switchHot(produit.id)"
                      >
                      <i class="flaticon-hot-sale  lh-1 me-8 position-relative top-1"></i>
                      <span v-if="!produit.hot"> Activer hot</span>
                      <span v-if="produit.hot"> Désactiver hot</span>
                      </a>
                    </li>
                    <li >
                      <router-link :to="{ name: 'EditProduitPage', params: { id: produit.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>Modifier</router-link>
                    </li>
                    <li >
                      <router-link :to="{ name: 'ViewProduitPage', params: { id: produit.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-eye lh-1 me-8 position-relative top-1"
                        ></i>Détails</router-link>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="suppression(produit.id,produits,'produits',`la catégorie ${produit.title}`)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>

<AddNewCategorieModal :selectedProduit="selectedProduit" :selectedProduitId="selectedProduitId" @close="recharger"/>
<AddPromotionModal :selectedProduit="selectedProduit" :selectedProduitId="selectedProduitId" @close="recharger"/>
<AddCollectionModal :selectedProduit="selectedProduit" :selectedProduitId="selectedProduitId" @close="recharger"/>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2";
import { Produit } from "../../../models/Produit";
import ApiService from "../../../services/ApiService";
import { suppression, error, getUrlApiForProductFiles, success } from "../../../utils/utils";
import PaginationComponent from '../../../components/Utilities/Pagination.vue';
import JwtService from "../../../services/JwtService";
import AddNewCategorieModal from "./AddNewCategorieModal.vue";
import AddPromotionModal from "./AddPromotionModal.vue";
import AddCollectionModal from "./AddCollectionModal.vue";
import axios from "axios";

export default defineComponent({
  name: "ListeProduit",
  components: {
    PaginationComponent,
    AddNewCategorieModal,
    AddPromotionModal,
    AddCollectionModal,
  },
  setup(){
    
    onMounted(() => {
      getAllProduits();
    });

    const produits = ref<Array<Produit>>([]);   
    const produit = ref<Produit>();
    const estMarchand = JwtService.getIfMarchand(false);
    const userId = JwtService.getUser()!;

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllProduits(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllProduits(page.value, limit.value, searchTerm.value );
    }

   // END PAGINATE

    function getAllProduits(page = 1, limi = 10, searchTerm = '') {
      return axios.get(`/produits?page=${page}&limit=${limi}&mot=${searchTerm}&`,{
            headers: {
                'userId': userId
            }
        })
      // return ApiService.get('/produits')
        .then(({ data }) => {
          produits.value = data.data.data;
          console.log('ART',produits.value)
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }

    // const endPromo = async (p, values) => {
    //     const promotionData = {
    //       produitId: p,
    //     };
    //     console.log('rfjfl', promotionData)
    //   ApiService.post('/endPromotions', promotionData)
    //     .then(({ data }) => {
    //       if (data.code == 200) {
    //       success(data.message);
    //       getAllProduits()
    //     }
    //     }).catch(({ response }) => {
    //       error(response.data.message);
    //     });
    // };

    const confirmEndPromo = async (produitId) => {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Voulez-vous vraiment retirer ce produit de la promotion?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, retirer!'
      });

      if (result.isConfirmed) {
        endPromo(produitId);
      }
    };

    const endPromo = async (produitId) => {
      const promotionData = { produitId };

      try {
        const response = await ApiService.post('/endPromotions', promotionData);
        if (response.data.code === 200) {
          success(response.data.message);
          getAllProduits();
        }
      } catch (error) {
        //errorHandler(error.response.data.message);
      }
    };

    const success = (message) => {
      Swal.fire({
          text: message,
          toast: true,
          icon: 'success',
          title: 'Message',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
    };

    // function getAllProduits() {
    //   return ApiService.get("/produits")
    //     .then(({ data }) => {
    //       produits.value = data.data.data
    //       console.log(data.data.data)
    //       return data.data.data;
    //     })
    //     .catch(({ response }) => {
    //       //error(response.data.msg)
    //   });
    // }
    
    function moddifier(EditProduit:Produit) {
      produit.value = EditProduit;
    }

    const recharger = () => {
      getAllProduits();
    };

    const deleteProduit = (id: number) => {
      ApiService.delete(`/produits/${id}`)
      .then(({ data }) => {
        Swal.fire({
          text: data.message,
          toast: true,
          icon: 'success',
          title: 'General Title',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Réssayer à nouveau!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      });

      for(let i = 0; i < produits.value.length; i++) {
        if (produits.value[i].id === id) {
          produits.value.splice(i, 1);
        }
      }
    };

    const switchProduit = (id) => {
      axios.put(`/produit/statut/${id}`)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          getAllProduits();
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    }

    // const mettrePromo = (id, sale_price) => {
    //   const promotionData = {
    //       produitId: id,
    //       is_sale: true,
    //       sale_price: sale_price,
    //     };
    //   axios.post("/startPromotions", promotionData)
    //     .then(({ data }) => {
    //       if (data.code == 200) {
    //       success(data.message);
    //       getAllProduits();
    //     }
    //     }).catch(({ response }) => {
    //       error(response.data.message);
    //     });
    // }

    // const suppCat = (produitId, categorieId) => {
    //   Swal.fire({
    //   text: "Vous êtes sur le point de faire une suppression",
    //   icon: "warning",
    //   buttonsStyling: true,
    //   showCancelButton: true,
    //   confirmButtonText: "Supprimer",
    //   cancelButtonText: `Annuler`,
    //   heightAuto: false,
    //   customClass: {
    //     confirmButton: "btn btn-danger",
    //   },
    // }).then((result) => {
    //   ApiService.delete(`/produitcategorie/${categorieId}/${produitId}`)
    //     .then(({ data }) => {
    //         Swal.fire({
    //           title: 'Succès',
    //           text: data.message,
    //           icon: "success",
    //           toast: true,
    //           timer: 5000,
    //           position: 'top-right',
    //           showConfirmButton: false,
    //         });
    //         getAllProduits();
    //     }).catch(({ response }) => {
    //       Swal.fire({
    //         text: response.data.message, 
    //         icon: "error",
    //         buttonsStyling: false,
    //         confirmButtonText: "Réssayer à nouveau!",
    //         heightAuto: false,
    //         customClass: {
    //           confirmButton: "btn fw-semobold btn-light-danger",
    //         },
    //       });
    //   });
    //   });
    // }

    const selectedProduit = ref<Produit | undefined>(undefined);
    const selectedProduitId = ref<number>();
      const sale_priceProd = ref<number>();

    const openAddNewCategorieModal = (modele: Produit) => {
    selectedProduit.value = { ...modele };
    selectedProduitId.value = modele.id;
  };

    const openAddPromotionModal = (modele: Produit) => {
      selectedProduit.value = { ...modele };
      selectedProduitId.value = modele.id;
      sale_priceProd.value= modele.sale_price
  };

  
  const openAddCollectionModal = (modele: Produit) => {
      selectedProduit.value = { ...modele };
      selectedProduitId.value = modele.id;
  };

    const switchFeature = (id) => {
      axios.put(`/produit/feature/${id}`)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          getAllProduits();
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    }

    const switchHot = (id) => {
      axios.put(`/produit/hot/${id}`)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          getAllProduits();
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    }
    
    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    return { produits,
      checkPermission,
     getAllProduits,
     deleteProduit,
     moddifier ,
     suppression,
     page, 
    totalPages,
    limit,
    totalElements,
    handlePaginate,
    rechercher,
    searchTerm,
    getUrlApiForProductFiles,
    openAddNewCategorieModal,
    selectedProduit,
    selectedProduitId,
    openAddPromotionModal,
    recharger,
    openAddCollectionModal,
    endPromo,confirmEndPromo,
    switchFeature,switchHot,
    switchProduit,sale_priceProd
  };
  },
});
</script>