<template>
  <div
    class="modal fade createNewModal"
    id="AddTailleModal"
    tabindex="-1"
    ref="addTailleModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="tailleForm" @submit="addTaille" :validation-schema="tailleSchema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Taille min <span class="text-danger">*</span>
                  </label>
                  <Field name="tailleMin" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la taille"/>
                  <ErrorMessage name="tailleMin" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Taille max <span class="text-danger">*</span>
                  </label>
                  <Field name="tailleMax" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la taille"/>
                  <ErrorMessage name="tailleMax" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12" v-if="btntext != 'Modifier'">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Categorie <span class="text-danger">*</span>
                </label>
                <Field  name="categories"  v-slot="{ field }" v-model="defaultSelectedCategories">
                  <Multiselect
                    :options="categoriesOptions"
                    :searchable="true"
                    track-by="label"
                    multiple="true"
                    mode="tags"
                    label="label"
                    v-bind = "field"
                    placeholder="Sélectionnez la catégorie"
                  />
                </Field>  
                <ErrorMessage name="categories" class="text-danger"/>
                <span class="text-danger" v-if="produitsError">La catégorie est obligatoire</span>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../../services/ApiService';
import * as Yup from 'yup';
import { Taille } from '../../../models/Taille';
import { error , success, hideModal } from '../../../utils/utils';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddTailleModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const tailleSchema = Yup.object().shape({
      tailleMin: Yup.number().typeError('Veuillez entrer des nombres').required('La taille maximale est obligatoire'),
      tailleMax: Yup.number().typeError('Veuillez entrer des nombres').required('La taille minimal est obligatoire'),
      // categories: Yup.string().when([], (value, schema) => {
      //   return btntext.value != 'Modifier'
      //     ? schema.notRequired()
      //     : schema.required("La catégorie est obligatoire")
      // }),
      // categories: Yup.array().required('La catégorie est obligatoire'),
      //categories: Yup.array().required('La catégorie est obligatoire'),
    });

    const tailleForm = ref<Taille | null>(null);
    const addTailleModalRef = ref<null | HTMLElement>(null);
    // const item = ref({ ...props.item });
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter une taille");
    const btntext = ref('Ajouter');
    const categoriesOptions = ref([]);

    watch(() => props.item, (newValue) => {
      getTaille(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier la taille";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter une taille";
         btntext.value = "Ajouter";
      }
    }
    const defaultSelectedCategories = ref<any[]>([]);
    const produitsError = ref(false); 

    watch(defaultSelectedCategories, () => {
      console.log('name', defaultSelectedCategories.value)
      if(defaultSelectedCategories.value.length > 0){
        produitsError.value = false
      }
      else {
        produitsError.value = true
      }
    })

    const getTaille = async (id: number) => {
    return ApiService.get("/tailles/"+id)
    .then(({ data }) => {
      const donnees = data.data;
      // const categories = donnees.categories;

      // let ids: number[] = [];
      // for (const categorie of categories) {
      //   ids.push(categorie.id); 
      // }
      // categories.value= ids;

      // defaultSelectedCategories.value = categories.value;
      for (const key in donnees) {
          tailleForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
      emit('openmodal', addTailleModalRef.value);
    })
    .catch(({ response }) => {
      error(response.data.message);
    });
}

    const addTaille = async (values: any, tailleForm) => {
      values = values as Taille;
      if(isUPDATE.value){
        ApiService.put("/tailles/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              tailleForm.resetForm();
              hideModal(addTailleModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        if(defaultSelectedCategories.value.length > 0){
        ApiService.post("/tailles",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              tailleForm.resetForm();
              hideModal(addTailleModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });}
        else{
          produitsError.value = true
        }
      }
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories/nopage');
        const categoriesData = response.data.data.data;
        categoriesOptions.value = categoriesData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchCatgories();
    });

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return { tailleSchema,
      addTailleModalRef,
      addTaille,
      tailleForm,
      title,btntext,resetValue,categoriesOptions,
      produitsError,
      defaultSelectedCategories,
    };
  },
});
</script>@/models/Taille