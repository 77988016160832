<template>
  <div
    class="modal fade createNewModal"
    id="AddIntervallePoidsModal"
    tabindex="-1"
    ref="addIntervallePoidsModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="intervallePoidsForm" @submit="addIntervallePoids" :validation-schema="intervallePoidsSchema">
            <div class="row">
              <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type <span class="text-danger">*</span>
                </label>
                <Field  name="type"  v-slot="{ field }">
                  <Multiselect
                    :options="typeValue"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le type"
                  />
                </Field>  
              </div>
              <ErrorMessage name="type" class="text-danger"/>
            </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Début Intervalle <span class="text-danger">*</span>
                  </label>
                  <Field name="debutIntervalle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le début de l'intervalle"/>
                  <ErrorMessage name="debutIntervalle" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Fin Intervalle <span class="text-danger">*</span>
                  </label>
                  <Field name="finIntervalle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la fin de l'intervalle"/>
                  <ErrorMessage name="finIntervalle" class="text-danger"/>
                </div>
              </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../services/ApiService';
import * as Yup from 'yup';
import { hideModal } from '../../utils/utils';
import { IntervallePoids } from '../../models/IntervallePoids';
import { error , success } from '../../utils/utils';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddIntervallePoidsModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const intervallePoidsSchema = Yup.object().shape({
      type: Yup.number().typeError('Veuillez entrer des chiffres').required("Le type est obligatoire"),
      debutIntervalle: Yup.number().typeError('Veuillez entrer des chiffres').required('Le début de l\'intervalle est obligatoire'),
      finIntervalle: Yup.number().typeError('Veuillez entrer des chiffres').required('La fin de l\'intervalle est obligatoire'),
    });

    const intervallePoidsForm = ref<IntervallePoids | null>(null);
    const addIntervallePoidsModalRef = ref<null | HTMLElement>(null);
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter un intervalle");
    const btntext = ref('Ajouter');

    const typeValue = 
      [
      {
          value: 1 ,
          label: "Colis postaux"
      },
      {
          value: 2 ,
          label: "Envois express"
      },
      {
          value: 3 ,
          label: "Poste aux lettres"
      },
      {
          value: 4 ,
          label: "Transport de colis/marchandises et Location de bus/camions"
      }
    ];

    watch(() => props.item, (newValue) => {
      getIntervallePoids(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier l'intervalle'";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter lintervalle";
         btntext.value = "Ajouter";
      }
    }

    const getIntervallePoids = async (id: number) => {
      return ApiService.get("/intervallePoids/"+id)
      .then(({ data }) => {
        // map data in form
        const donnees = data.data;
        for (const key in donnees) {
          intervallePoidsForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addIntervallePoidsModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addIntervallePoids = async (values: any, IntervallePoidsForm) => {
      values = values as IntervallePoids
      if(isUPDATE.value){
        ApiService.put("/intervallePoids/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              IntervallePoidsForm.resetForm();
              hideModal(addIntervallePoidsModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        ApiService.post("/intervallePoids/",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              IntervallePoidsForm.resetForm();
              hideModal(addIntervallePoidsModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return { intervallePoidsSchema,
      addIntervallePoidsModalRef,
      addIntervallePoids,
      intervallePoidsForm,
      title,btntext,resetValue,
      typeValue
    };
  },
});
</script>