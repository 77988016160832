<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="produitForm" @submit="addProduit" :validation-schema="produitSchema">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Titre <span class="text-danger">*</span>
              </label>
              <Field name="title" type="text" v-model="title" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le title" />
              <ErrorMessage name="title" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Slug <span class="text-danger">*</span>
              </label>
              <Field name="slug" type="text" readonly="true" v-model="slug"
                class="form-control shadow-none fs-md-15 text-black" placeholder="" />
              <ErrorMessage name="slug" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4" v-if="estMarchand">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marchand <span class="text-danger">*</span>
              </label>
              <Field name="marchand" v-model="userName" class="form-control shadow-none fs-md-15 text-black input-readonly" readonly="true"/>
              <ErrorMessage name="marchand" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4" v-else>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marchand <span class="text-danger">*</span>
              </label>
              <Field name="marchand" v-slot="{ field }">
                <Multiselect :options="marchandOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field" @select="selectMarchand(field.value)"
                  placeholder="Sélectionnez le marchand" />
              </Field>
              <ErrorMessage name="marchand" class="text-danger" />
            </div>
          </div>
          
          <!-- <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Lien<span class="text-danger">*</span>
                </label>
                <Field name="lien" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le lien"/>
                <ErrorMessage name="lien" class="text-danger"/>
              </div>
            </div> -->

        
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Small Description du produit<span class="text-danger">*</span>
              </label>
              <div class="mb-0">
                <QuillEditor ref="smallDescriptionEditor" theme="snow" placeholder="Coute description" toolbar="full"
                  name="smallDescription" />
              </div>
              <ErrorMessage name="smallDescription" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Description du produit <span class="text-danger">*</span>
              </label>
              <div class="mb-0">
                <QuillEditor ref="descriptionEditor" theme="snow" placeholder="Écrivez la description" toolbar="full"
                  name="description" />
              </div>
              <ErrorMessage name="description" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="product-upload  mb-10">
              <p>Images</p>
              <div class="dz-message needsclick">
                <DropZone name="fichiers[]" 
                  :maxFilesize="50000000" 
                  :maxFiles="Number(10000000000)" 
                  :uploadOnDrop="true"
                  @addedFile="onFileAdd"
                  @removedFile="onFileRemove"
                  :options="dropzoneOptions"
                  @vdropzone-success="onSuccess"
                  @vdropzone-error="onError">
                  <div class="default-image-container" v-if="!hasFiles">
                    <img :src="defaultImage" alt="Default" class="default-image" />
                    <p>Drag and drop files here, or click to select files</p>
                  </div>
                </DropZone>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-10">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Image <span class="text-danger">*</span>
              </label>
              <Field name="urlImage" type="file" accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image" />
              <ErrorMessage name="urlImage" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2 mt-10">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Quantite <span class="text-danger">*</span>
              </label>
              <Field name="depot" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer la quantité" />
              <ErrorMessage name="depot" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2 mt-10">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Quantité rupture
              </label>
              <Field name="quantiteRupture" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer la quantité de rupture" />
              <ErrorMessage name="quantiteRupture" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2 mt-10">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Numéro de série
              </label>
              <Field name="numeroSerie" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le numéro de série" />
              <ErrorMessage name="numeroSerie" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2 mt-10">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Prix de vente<span class="text-danger">*</span>
              </label>
              <Field name="price" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrez le prix de vente" />
              <ErrorMessage name="price" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4 " v-if="estMarchand">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Categorie du produit<span class="text-danger">*</span>
              </label>
              <Field name="categories" v-slot="{ field }">
                <Multiselect :options="categoriesMarchandOptions" :searchable="true" track-by="label" multiple="true"
                  mode="tags" label="label" v-bind="field" placeholder="Sélectionnez la catégorie" />
              </Field>
              <ErrorMessage name="categories" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4 " v-else>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Categorie du produit<span class="text-danger">*</span>
              </label>
              <Field name="categories" v-slot="{ field }">
                <Multiselect :options="categoriesOptions" :searchable="true" track-by="label" multiple="true"
                  mode="tags" label="label" v-bind="field" placeholder="Sélectionnez la catégorie" />
              </Field>
              <ErrorMessage name="categories" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Couleur <span class="text-danger">*</span>
              </label>
              <Field name="couleur" v-slot="{ field }">
                <Multiselect :options="couleurOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field" placeholder="Sélectionnez la couleur" />
              </Field>
              <ErrorMessage name="couleur" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Prix d'achat
              </label>
              <Field name="prixAchat" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrez le prix d'achat" />
              <ErrorMessage name="prixAchat" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Seuil alerte
              </label>
              <Field name="seuilAlerte" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrez le seuil" />
              <ErrorMessage name="seuilAlerte" class="text-danger" />
            </div>
          </div>
          
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Poids (kg)<span class="text-danger">*</span>
              </label>
              <!-- <Field name="poids" v-slot="{ field }">
                <Multiselect :options="poidsOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field" placeholder="Sélectionnez le poids" />
              </Field> -->
              <Field name="poids" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le poids" />
              <ErrorMessage name="poids" class="text-danger" />
            </div>
          </div>
          <!-- <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10" >
                  Autres images
                </label>
              <div class="file-upload text-center position-relative">
                <img :src="imageUrl" alt="Uploaded Image" class="preview-image" />
                <span class="d-block text-muted">
                  Drop Files Here Or
                  <span @click="uploadImage" class="text-black fw-medium position-relative">
                    Click To Upload
                  </span>
                </span>
                <input
                  type="file"
                  class="d-block shadow-none border-0 position-absolute start-0 end-0 top-0 bottom-0 z-1 opacity-0"
                  ref="fileInput"
                  @change="handleFileChange"
                />
              </div>
              </div>
            </div> -->
          <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie Info<span class="text-danger">*</span>
                </label>
                <Field  name="categorie"  v-slot="{ field }">
                  <Multiselect
                    :options="categorieOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez la catégorie"
                  />
                </Field>  
                <ErrorMessage name="categorie" class="text-danger"/>
              </div>
            </div> -->
            
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marque <span class="text-danger">*</span>
              </label>
              <Field name="marque" v-slot="{ field }">
                <Multiselect :options="marqueOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field" placeholder="Sélectionnez la marque" />
              </Field>
              <ErrorMessage name="marque" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modèle <span class="text-danger">*</span>
              </label>
              <Field name="model" v-slot="{ field }">
                <Multiselect :options="modeleOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field" placeholder="Sélectionnez le modèle" />
              </Field>
              <ErrorMessage name="model" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Taille <span class="text-danger">*</span>
              </label>
              <Field name="taille" v-slot="{ field }">
                <Multiselect :options="tailleOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field" placeholder="Sélectionnez la taille" />
              </Field>
              <ErrorMessage name="taille" class="text-danger" />
            </div>
          </div>
          
          
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit">
                Ajouter un produit
              </button>
              <router-link to="/liste-produits" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success, generateSlug } from "../../../utils/utils";
import { useRouter } from "vue-router";
import ApiService from "../../../services/ApiService";
import { Produit } from "../../../models/Produit";
import * as Yup from "yup";
import Multiselect from '@vueform/multiselect'
import axios from "axios";
import DropZone from "dropzone-vue";
import JwtService from "@/services/JwtService";

export default defineComponent({
  name: "AddProduit",
  components: {
    Form,
    Field,
    DropZone,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const produitSchema = Yup.object().shape({
      title: Yup.string().required('Le titre est obligatoire'),
      numeroSerie: Yup.string().notRequired(),
      smallDescription:Yup.string().notRequired(),
      description: Yup.string().notRequired(),
      urlImage: Yup.mixed().required('L\'image est obligatoire'),
      marchand: Yup.string().required("Le marchand est obligatoire"),
      slot: Yup.string().notRequired(),
      depot: Yup.number().typeError('Veuillez entrer des nombres').required('La quantité est obligatoire'),
      quantiteRupture: Yup.number().notRequired(),
      prixAchat: Yup.number().notRequired(),
      price: Yup.number().typeError('Veuillez entrer des nombres').required('Le prix de vente est obligatoire'),
      seuilAlerte: Yup.number().notRequired(),
      // categories: Yup.string().required('La catégorie est obligatoire'),
      // categories: Yup.string().required('La catégorie est obligatoire'),
      marque: Yup.string().required('La marque est obligatoire'),
      model: Yup.string().required('Le modèle est obligatoire'),
      taille: Yup.string().required('La taille est obligatoire'),
      couleur: Yup.string().required('La couleur est obligatoire'),
      poids: Yup.number().typeError('Veuillez entrer des nombres').required('Le poids est obligatoire'),
    });

    const defaultImage = 'path/to/your/default-image.jpg'; // Chemin de l'image par défaut
    const hasFiles = ref(false); // Indicateur pour savoir si des fichiers ont été déposés

    const dropzoneOptions = {
      url: 'your-upload-url', // URL de téléchargement des fichiers
      thumbnailWidth: 150,
      maxFilesize: 2,
      headers: { "My-Awesome-Header": "header value" }
    };

    const onSuccess = (file) => {
      hasFiles.value = true; // Mettre à jour l'indicateur lorsqu'un fichier est déposé avec succès
    };

    const onError = (file, message) => {
      console.error('File upload error:', message);
    };

    const produitForm = ref<Produit | null>(null);
    const router = useRouter();
    const tailleOptions = ref([]);
    const poidsOptions = ref([]);
    const couleurOptions = ref([]);
    const modeleOptions = ref([]);
    const marqueOptions = ref([]);
    //const categorieOptions= ref([]);
    const categoriesOptions = ref([]);
    const categoriesMarchandOptions = ref([]);
    const descriptionEditor = ref();
    const smallDescriptionEditor= ref();
    const title = ref();
    const toutFichier = ref<Array<File>>([])
    const slug = ref();
    const vendor = ref();
    const march = ref();
    const marchandOptions = ref();
    const estMarchand = ref<boolean>(false);
    const userName = ref("");

    const selectMarchand = (a) => {
      console.log('a',a);
      const [id, codeMarchand] = a.split('|');
      const code = a.split('|')
      vendor.value = code[2]; 
      march.value = id;
      console.log('codeMarchand.value', codeMarchand);
      console.log('march.value', id);
      console.log('vendor.value', vendor.value);
    }
    // const selectMarchand = (a) => {
    //   vendor.value = a;
    //   vendor.value = a.split('|', 1);
    //   console.log('vendor.value', a)
    //   console.log('vendor.value', vendor.value)
    // }

    watch(title, (newValue) => {
      console.log('title', title.value)
      console.log('generateSlug', slug.value)
      slug.value = generateSlug(newValue)
      console.log('after', slug.value)
    })

    const onFileAdd = (file: any) => {
      toutFichier.value.push(file["file"]);
    };
    const addProduit = async (values) => {
      values['description'] = descriptionEditor.value.getHTML();
      values['smallDescription'] = smallDescriptionEditor.value.getHTML();
      values['slug'] = slug.value;
      if(JwtService.getIfMarchand(false)!){
        values['marchand'] = parseInt(JwtService.getIdMarchand()!)
        values['vendor'] = JwtService.getCodeMarchand();
      }
      else{
        values['marchand'] = parseInt(march.value);
        values['vendor'] = vendor.value;
      }
      if(toutFichier.value.length > 0){
        values['autreFichier[]'] = [];
        values['autreFichier[]'] =  toutFichier.value.map((fichier) => (fichier));
      }
      values['userId']=parseInt(JwtService.getUser()!);
      values['estMarchand']=JwtService.getIfMarchand(false)!;
      console.log('offvale',values)
      // ApiService.post("/produits", values)
      axios.post("/produits", values, { headers: { 'Content-Type': 'multipart/form-data', 'Accept': '*/*' } })
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            //resetForm();
            router.push({ name: "ListeProduitPage" });
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories/nopage');
        const marchandsData = response.data.data;
        categoriesOptions.value = marchandsData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCatgoriesMarchand = async (userId) => {
      try {
        const response = await ApiService.get(`/categories/marchands/${parseInt(userId)}`);
        const catMarchandsData = response.data.data;
        categoriesMarchandOptions.value = catMarchandsData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchMarchands = async () => {
      try {
        const response = await ApiService.get('/marchands?limit=0&');
        const marchandsData = response.data.data.data;
        marchandOptions.value = marchandsData.map((marchands) => ({
          value: `${marchands.id}|${marchands.nom}|${marchands.codeMarchand}`,
          label: (marchands.raisonSocial != null)? `${marchands.codeMarchand} | ${marchands.raisonSocial}`:`${marchands.codeMarchand} | ${marchands.nom} ${marchands.prenom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchTailles = async () => {
      try {
        const response = await ApiService.get('/tailles?limit=0&');
        const taillesData = response.data.data.data;
        tailleOptions.value = taillesData.map((taille) => ({
          value: taille.id,
          label: `${taille.tailleMin} - ${taille.tailleMax}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchPoids = async () => {
      try {
        const response = await ApiService.get('/poids?limit=0&');
        const poidsData = response.data.data.data;
        poidsOptions.value = poidsData.map((poids) => ({
          value: poids.id,
          label: `${poids.poidsMin} - ${poids.poidsMax}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCouleurs = async () => {
      try {
        const response = await ApiService.get('/couleurs?limit=0&');
        const couleursData = response.data.data.data;
        couleurOptions.value = couleursData.map((couleur) => ({
          value: couleur.id,
          label: `${couleur.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchModeles = async () => {
      try {
        const response = await ApiService.get('/models?limit=0&');
        const modelesData = response.data.data.data;
        modeleOptions.value = modelesData.map((modele) => ({
          value: modele.id,
          label: `${modele.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const onFileRemove = (file: File) => {
      //
      console.log("J'ai retiré ceci ==> ", file);
      console.log("LLLL ==> ", file["file"].name);
      console.log("TOUS FICHIERS ==> ", toutFichier.value);
      // Recherche de l'index du fichier dans le tableau
      const index = toutFichier.value.findIndex(fil => ((fil.name === file["file"].name) && (fil.size === file["file"].size) && (fil.type === file["file"].type)));
      if (index !== -1) {
        toutFichier.value.splice(index, 1);
      } else {
        console.log(`Le fichier ${file["file"].name} n'a pas été trouvé dans le tableau.`);
      }
    }

    const fetchMarques = async () => {
      try {
        const response = await ApiService.get('/marques?limit=0&');
        const marquesData = response.data.data.data;
        marqueOptions.value = marquesData.map((marque) => ({
          value: marque.id,
          label: `${marque.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const serviceOptions = ref([]);
    const handleUploadError = (file, message, xhr)=> {
      console.log('OUPS IL Y AUNE ERREUR BIZARRE');
      console.error('Erreur de téléchargement:', message);
    }
    onMounted(() => {
      //fetchCategorieInfos();
      fetchCatgories();
      fetchMarques();
      fetchModeles();
      fetchCouleurs();
      fetchTailles();
      fetchMarchands();
      fetchPoids();
      if(JwtService.getUser()){
        userName.value = JwtService.getCodeMarchand()!;
        estMarchand.value = JwtService.getIfMarchand(false)!;
        fetchCatgoriesMarchand(JwtService.getUser());
      }
      console.log('user',JwtService.getCodeMarchand());
      console.log('marchand',JwtService.getIfMarchand(false))
      console.log('marchandId',JwtService.getIdMarchand())
    });
    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    const checkParentPermission = (permissions) => {
      for (const permission of permissions) {
        if (checkPermission(permission)) {
          return true; 
        }
      }
      return false; 
    }

    return {
      handleUploadError,
      produitSchema,
      addProduit,
      produitForm,
      onFileAdd,
      onFileRemove,
      //categorieOptions,
      marqueOptions,
      modeleOptions,
      couleurOptions,
      tailleOptions,
      serviceOptions,
      categoriesOptions,
      descriptionEditor,
      smallDescriptionEditor,
      title, slug,
      marchandOptions,
      selectMarchand,
      defaultImage,
      hasFiles,
      dropzoneOptions,
      onSuccess,
      onError,
      checkPermission,
      checkParentPermission,
      estMarchand,categoriesMarchandOptions,
      userName,poidsOptions,
    };
  },
});
</script>
<style scoped>
.dropzone-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.default-image-container {
  text-align: center;
  margin: 20px 0;
}
.default-image {
  width: 100px;
  height: auto;
}

.input-readonly {
  background-color: #f0f0f0;
  color: #888888;
  border-color: #cccccc;
}
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
</style>