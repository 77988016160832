import { Modal } from "bootstrap";
import moment from "moment";
import ApiService from "@/services/ApiService";
import Swal from "sweetalert2";
import { format } from 'date-fns';
import { defineComponent, onMounted, ref, watch } from "vue";
//import { format } from 'date-fns/esm';
import { fr } from 'date-fns/locale';
import tinymce from 'tinymce';
import JwtService from "@/services/JwtService";
import slugify from 'slugify';
import CryptoJS from "crypto-js";
import axios from "axios";



const cleSecrete = "=_,riNe5Mw3L*:B87qzpN8LfiUc2~5xE;9268G+3z@JRe)W.x6A%67hu^-?$BKmPM32s7[";
export function cryptage(lemot = "") {
  let motCrypter = "";
  let i = 0;
  do {
    motCrypter = CryptoJS.AES.encrypt((lemot).toString(), cleSecrete).toString();
    console.log(" Etape "+ i++, "  ===> ", motCrypter);
  } while (motCrypter.includes('/')); // Vérifier s'il contient "/"

  return motCrypter;
}

export function decryptage(lemot = "") {
  const bytes = CryptoJS.AES.decrypt(lemot, cleSecrete);
  return bytes.toString(CryptoJS.enc.Utf8);
}

const initTinyMCE = (target, config) => {
  return tinymce.init({
    target,
    ...config,
  });
};

const getDatePlusXDays = (x: number) => {
  const currentDate = new Date();
  const futureDate = new Date();

  futureDate.setDate(currentDate.getDate() + x);
  // Obtenez les composants de la date
  const year = futureDate.getFullYear();
  const month = (futureDate.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0
  const day = futureDate.getDate().toString().padStart(2, '0');

  // Formattez la date comme "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

const destroyTinyMCE = (editor) => {
  if (editor) {
    editor.destroy();
  }
};

const generateSlug = (value) => {
  return slugify(value, {
    lower: true, // Mettre en minuscules
    strict: true, // Supprimer les caractères spéciaux
    locale: 'fr' // Utiliser la locale française
  });
};


const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal?.hide();
};

const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl);
  myModal?.show()
};



const success = (message) => {
  Swal.fire({
    title: 'Succès',
    text: message,
    icon: "success",
    toast: true,
    timer: 5000,
    position: 'top-right',
    showConfirmButton: false,
  });
}

const error = (message) => {
  Swal.fire({
    title: 'Erreur',
    text: message,
    icon: "error",
    toast: true,
    timer: 5000,
    position: 'top-right',
    showConfirmButton: false
  });
}

const format_date = (value) => {
  if (value) {
    return format(new Date(value), 'dd-MM-yyyy HH:mm', { locale: fr });
    //return moment(String(value)).format('DD-MM-YYYY hh:mm')
  }
}
const format_Date = (date) => {
  if (date) {
    return format(new Date(date), 'dd-MM-yyyy', { locale: fr });
  }
}

const separateur = (montant) => {
  if (montant) {
    return montant.toLocaleString('fr-FR');
  }
}

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const getUrlApiForFiles = (nomFichier: string | null) => {
  if (nomFichier) {
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/${nomFichier}`;
  } else {
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/Erreur404.pdf`;
  }
}

const getUrlApiForProductFiles = (nomFichier: string | null) => {
  if (nomFichier) {
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/Produits/${nomFichier}`;
  } else {
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/Erreur404.pdf`;
  }
}



const urlToFile = async (url: string, filename: string, mimeType: string): Promise<File | null> => {
  try {
    //axios.defaults.baseURL = 'http://localhost:3007';
    //url = "/uploads/Produits/20240628000420420tEHTB2h75c.jpg";
    const response = await axios.get(url, { responseType: 'blob' });
    console.log("JE viens de récupérer une image")
    return new File([response.data], filename, { type: mimeType });
  } catch (error) {
    console.error('Erreur lors de la récupération du fichier :', error);
    return null;
  }
};

// const urlToFile = (url: string, filename: string, mimeType: string): Promise<File | null> => {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('GET', url);
//     xhr.responseType = 'blob';

//     xhr.onload = () => {
//       if (xhr.status === 200) {
//         const blob = xhr.response;
//         resolve(new File([blob], filename, { type: mimeType }));
//       } else {
//         reject(new Error(`Erreur de réseau : ${xhr.statusText}`));
//       }
//     };

//     xhr.onerror = () => {
//       reject(new Error('Erreur lors de la récupération du fichier'));
//     };

//     xhr.send();
//   });
// };







const getAssetPath = (path: string): string => {
  return '' + path;
};

// function getAllCategorieAbonnes(route:string,element:any) {
//   return ApiService.get(`/${route}`)
//   .then(({ data }) => {
//     console.log(data);
//     element.value = data.data
//   })
//   .catch(({ response }) => {
//     console.log(response)
//   });
// } 

const suppression = (id: number, element: any, route: string, entite: string) => {
  Swal.fire({
    text: "Vous êtes sur le point de supprimer " + entite + ". Etes-vous sûr ?",
    icon: "warning",
    buttonsStyling: true,
    showCancelButton: true,
    confirmButtonText: "Supprimer",
    cancelButtonText: `Annuler`,
    heightAuto: false,
    customClass: {
      confirmButton: "btn btn-danger",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      ApiService.delete(`/${route}/${id}`)
        .then(({ data }) => {
          Swal.fire({
            title: 'Succès',
            text: data.message,
            icon: "success",
            toast: true,
            timer: 5000,
            position: 'top-right',
            showConfirmButton: false,
          });
          for (let i = 0; i < element.length; i++) {
            if (element[i].id === id) {
              element.splice(i, 1);
            }
          }
        }).catch(({ response }) => {
          Swal.fire({
            text: response.data.message, //'Oups il y a un problème',//
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    } else if (result.isDenied) {
      Swal.fire("La suppression n'est pas passée", "", "info");
    }
  });
};

export {
  generateSlug, getDatePlusXDays,urlToFile, removeModalBackdrop, suppression, separateur, hideModal, getAssetPath, format_Date, showModal, format_date, success, error, initTinyMCE, destroyTinyMCE, getUrlApiForFiles, getUrlApiForProductFiles,
};

