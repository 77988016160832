<template>
  <div
    class="modal fade createNewModal"
    id="AddNewCategorieModal"
    tabindex="-1"
    ref="addAddNewCategorieModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">
        </h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <h5>Vous voulez ajouter de(s) nouveau(x) categorie(s) à cette poids: {{ selectedPoids?.nom }}</h5>
        <Form ref="categorieForm" @submit="addCategorie" :validation-schema="categorieSchema">
          <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Categories <span class="text-danger">*</span>
                  </label>
                  <Field name="categorie" v-slot="{ field }">
                    <Multiselect
                      :options="categoriesOptions"
                      :searchable="true"
                      multiple="true"
                      mode="tags"
                      track-by="label"
                      label="label"
                      v-model = "field.value"
                      v-bind = "field"
                      placeholder="Sélectionnez le categorie"
                    />
                  </Field>  
                </div>
                <ErrorMessage name="categorie" class="text-danger"/>
              </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              Envoyer
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch} from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, hideModal, success } from '@/utils/utils';
import { useRouter, useRoute } from 'vue-router';
import Multiselect from '@vueform/multiselect'
import { Poids } from '@/models/Poids';
import axios from 'axios';

export default {
  name: "AddNewCategorieModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    selectedPoids: Object as () => Poids | undefined,
  },
  setup: (props, { emit }) => {

    const categorieSchema = Yup.object().shape({
      categorie: Yup.array().required("La categorie est obligatoire"),
    });

    const categorieForm =  ref<Poids | null>(null);
    const addAddNewCategorieModalRef = ref<null | HTMLElement>(null);
    //const router = useRouter();
    const userForm = ref<Poids>();
    const route = useRoute();
    const  poids = ref();
    const categoriesOptions = ref([]);

    const fetchCategories = async (poidsId) => {
    try {
      if (selectedPoidsId.value) {
        const response = await axios.get(`/resteante/poidscategories/${poidsId}`);
        const categoriesData = response.data.data;
        categoriesOptions.value = categoriesData.map((categorie) => ({
          value: categorie.id,
          label: categorie.name,
        }));
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de la récupération des rôles :", error);
    }
  };

  function getPoids(id:number) {
    ApiService.get("/poids/"+id.toString())
      .then(({ data }) => {
        for (const key in data.data) {
          userForm.value?.setFieldValue(key, 
          (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
        );
        }
    })
    .catch(({ response }) => {
      error(response.message);
    });
  }
  
    const selectedPoids = ref<Poids | undefined>(undefined);
    const selectedPoidsId = ref<number>();

    const addCategorie = async (values, {resetForm}) => {
      const categorieData = {
        poidsId: selectedPoids.value?.id,
        categories: values.categorie,
      };
      console.log('rfjfl', categorieData)
    ApiService.post("/poids/categories/"+categorieData.poidsId, categorieData)
      .then(({ data }) => {
        if (data.code == 200) {
        success(data.message);
        resetForm();
        hideModal(addAddNewCategorieModalRef.value);
        emit('close');
        emit('getAllPoids',data.data);
      }
      }).catch(({ response }) => {
        error(response.data.message);
      });
  };

    watch(() => props.selectedPoids, (newValue) => {
      selectedPoids.value = newValue;
    if(newValue){
      selectedPoidsId.value = newValue.id;
      fetchCategories(selectedPoidsId.value);
    }
  });

    onMounted(() => {
    if(route.params.id) {
      getPoids(parseInt(route.params.id as string));
    }
  });

    return {categorieSchema,
      addCategorie,
       categorieForm,
       addAddNewCategorieModalRef,
        getPoids,
        categoriesOptions,
        selectedPoidsId
       };
  },
};
</script>